import { getDocs, query, where } from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    list: [],
    customConnectors: [],
  },
  getters: {
    getAll: state => state.list,
    getCustomConnectors: state => state.customConnectors,
    getMappingsConnectors: state => state.list.filter(el => el.supports.includes('mappings')),
    getPrefillsConnectors: state => state.list.filter(el => el.supports.includes('prefills')),
    getInstructionsConnectors: state => state.list.filter(el => el.supports.includes('instructions')),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
    UPDATE_CUSTOM_CONNECTORS(state, payload) {
      state.customConnectors = payload
    },
  },
  actions: {
    /**
     * Fetches all native connectors.
     *
     * @param {Object} Object.commit
     *
     * @returns {Promise}
     */
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { sharedConnectors } = tenantCtx()

        getDocs(sharedConnectors)
          .then(querySnapshot => {
            const data = JSON.parse(
              JSON.stringify(
                querySnapshot.docs.map(
                  docRef => docRef.data(),
                ),
              ),
            )

            commit('UPDATE_LIST', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches all custom connectors.
     *
     * @param {Object} Object.commit
     *
     * @returns {Promise}
     */
    fetchCustomConnectors({ commit }) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()

        const q = query(
          integrations,
          where('type', '==', 'custom'),
          where('method', '!=', 'GET'),
        )

        getDocs(q)
          .then(querySnapshot => {
            const data = JSON.parse(
              JSON.stringify(
                querySnapshot.docs.map(
                  docRef => {
                    const { type, name, identifier } = docRef.data()

                    return {
                      key: identifier,
                      label: name,
                      supports: ['mappings', 'instructions'],
                      type: type,
                    }
                  },
                ),
              ),
            )

            commit('UPDATE_CUSTOM_CONNECTORS', data)
            resolve(data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
  },
}
