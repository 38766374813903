import { $themeBreakpoints } from '@themeConfig'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loading: false,
    dataChanged: false,
    validationState: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state

      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'

      return 'xs'
    },
    loading: state => state.loading,
    getDataChanged: state => state.dataChanged,
    getValidationState: state => state.validationState,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_LOADING(state, val) {
      state.loading = val
    },
    DATA_CHANGED(state, payload) {
      state.dataChanged = payload
    },
    SET_VALIDATION_STATE(state, payload) {
      // Please do refer to Vue's Reactivity in Depth for the code below
      // @see https://v2.vuejs.org/v2/guide/reactivity.html
      // This deprecates with Vue 3
      Vue.set(state.validationState, payload.tab, payload.state)
    },
    EMPTY_VALIDATION_STATE(state) {
      state.validationState = {}
    },
  },
  actions: {},
}
