import i18n from '@/libs/i18n'

export default [
  {
    path: '/ui-libraries',
    name: 'ui-libraries',
    component: () => import('@/views/themes/ui-libraries/UILibraries.vue'),
    meta: {
      pageTitle: i18n.t('UI Libraries'),
      action: 'read',
      resource: 'ui_libraries',
    },
  },
  {
    path: '/ui-libraries/new',
    name: 'ui-libraries-new',
    component: () => import('@/views/themes/ui-libraries/NewUILibrary.vue'),
    meta: {
      pageTitle: i18n.t('New UI Library'),
      action: 'create',
      resource: 'ui_libraries',
      breadcrumb: [
        {
          text: i18n.t('UI Libraries'),
          to: '/ui-libraries',
        },
        {
          text: '',
          field: 'title',
          active: true,
        },
        {
          text: i18n.t('New'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/ui-libraries/:id/edit',
    name: 'ui-libraries-edit',
    component: () => import('@/views/themes/ui-libraries/EditUILibrary.vue'),
    meta: {
      pageTitle: i18n.t('Edit UI Library'),
      action: 'update',
      resource: 'ui_libraries',
      breadcrumb: [
        {
          text: i18n.t('UI Libraries'),
          to: '/ui-libraries',
        },
        {
          text: '',
          field: 'title',
          active: true,
        },
        {
          text: i18n.t('Edit'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/themes/ui-partials',
    name: 'themes-ui-partials',
    component: () => import('@/views/themes/ui-partials/UIPartials.vue'),
    meta: {
      pageTitle: i18n.t('UI Partials'),
      action: 'read',
      resource: 'ui_libraries',
    },
  },
]
