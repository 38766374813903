/* eslint-disable no-param-reassign */
// External
import {
  getBlob,
  uploadString,
  deleteObject,
  getDownloadURL,
  ref as storageRef,
} from 'firebase/storage'
import {
  doc,
  where,
  query,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
} from 'firebase/firestore'
import { getUnix } from '@/libs/date-format'
import html2canvas from 'html2canvas'
import { RevisionEventType } from '@core/revisions/revisions'

// Local
import store from '@/store'
import i18n from '@/libs/i18n'
import { allowed, FEATURES } from '@/auth'
import {
  constructQuery,
  updateIframeElement,
  retrieveUILibraryExternalResources as retrieveExternalResources,
} from '@core/utils/utils'

import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'

export default {
  namespaced: true,
  state: {
    current: {
      data: {},
    },
    currentFiles: {},
    activeUILibrary: null,
    currentComponents: [],
    externalResources: [],
    tenantUILibrariesList: [],
    sharedUILibrariesList: [],
    uiComponentThumbnails: [],
    thumbnailCanvasElement: null,
    lastActiveUILibraryEditTab: localStorage.getItem('novti-last-active-ui-library-edit-tab') || 0,
  },
  getters: {
    getCurrent: state => state.current,
    getCurrentFiles: state => state.currentFiles,
    getActiveUILibrary: state => state.activeUILibrary,
    getCurrentComponents: state => state.currentComponents,
    getTenantUILibraries: state => state.tenantUILibrariesList,
    getSharedUILibraries: state => state.sharedUILibrariesList,
    getUIComponentThumbnails: state => state.uiComponentThumbnails,
    getThumbnailCanvasElement: state => state.thumbnailCanvasElement,
    getLastActiveUILibraryEditTab: state => state.lastActiveUILibraryEditTab,
    getExternalResources: state => type => state.externalResources.filter(item => item.type === type),
    getActiveExternalResources: state => type => state.activeUILibrary?.externalResources.filter(item => item.type === type),
  },
  mutations: {
    SET_CURRENT_FILES(state, payload) {
      state.currentFiles = payload
    },
    SET_ACTIVE_UI_LIBRARY(state, payload) {
      state.activeUILibrary = payload ?? {}
    },
    SET_CURRENT_COMPONENTS(state, payload) {
      state.currentComponents = payload ?? []
    },
    SET_THUMBNAIL_CANVAS_ELEMENT(state, payload) {
      state.thumbnailCanvasElement = payload
    },
    SET_CURRENT(state, payload) {
      state.current = {
        snapshot: payload,
        data: { ...payload.data(), id: payload.id },
      }
    },
    SET_CURRENT_EXTERNAL_RESOURCES(state, payload) {
      state.externalResources = payload
    },
    UPDATE_TENANT_UI_LIBRARIES_LIST(state, payload) {
      state.tenantUILibrariesList = payload
    },
    UPDATE_SHARED_UI_LIBRARIES_LIST(state, payload) {
      state.sharedUILibrariesList = payload
    },
    SET_UI_COMPONENT_THUMBNAILS(state, payload) {
      state.uiComponentThumbnails = payload
    },
    DESTROY_CURRENT(state) {
      state.current = {}
    },
    SET_LAST_ACTIVE_UI_LIBRARY_EDIT_TAB(state, payload) {
      localStorage.setItem('novti-last-active-ui-library-edit-tab', payload)

      state.lastActiveUILibraryEditTab = payload
    },
  },
  actions: {
    /**
     * Creates a document by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise(async (resolve, reject) => {
        if (!allowed(FEATURES.UI_LIBRARIES_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your UI Libraries limit'),
            variant: 'danger',
          })

          return
        }

        const { uiLibraries } = tenantCtx()
        const { uiLibrariesStorage } = tenantCtx()

        const docRef = await doc(uiLibraries)

        // This is required if we want to filter on those fields, as per Firestore query limitations
        // eslint-disable-next-line no-param-reassign
        payload.id = docRef.id
        payload.active = false
        payload.deletedAt = null
        payload.creating = true
        payload.compiling = false
        payload.createdAt = getUnix()
        payload.updatedAt = getUnix()
        payload.storage = `${uiLibrariesStorage.fullPath}/${docRef.id}`

        setDoc(docRef, payload)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_UI_LIBRARIES,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })

            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('A new {title} has been created 🚀', {
                title: i18n.t('UI Library'),
              }),
            })

            resolve(docRef)
          })
          .catch(error => {
            console.debug(error)

            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the UI Library'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches the shared UI Libraries.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {Object} obj.dispatch
     *
     * @returns {Promise}
     */
    fetchSharedUILibraries({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const { sharedUILibraries } = tenantCtx()

        const q = constructQuery(sharedUILibraries, 'createdAt', {
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(async querySnapshot => {
            const uiLibraries = await Promise.all(
              querySnapshot.docs.map(async docRef => ({
                id: docRef.id,
                _uiLibraryThumbnails: await dispatch('fetchUILibraryThumbnails', { id: docRef.id, target: 'shared' }),
                ...docRef.data(),
              })),
            )

            commit('UPDATE_SHARED_UI_LIBRARIES_LIST', uiLibraries)
            resolve(uiLibraries)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Novti UI Libraries') }),
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches the teant's UI Libraries.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {Object} obj.dispatch
     *
     * @returns {Promise}
     */
    fetchTenantUILibraries({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()

        const q = constructQuery(uiLibraries, 'updatedAt', {
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(async querySnapshot => {
            const tenantUILibraries = await Promise.all(
              querySnapshot.docs.map(async docRef => ({
                id: docRef.id,
                _uiLibraryThumbnails: await dispatch('fetchUILibraryThumbnails', { id: docRef.id }),
                ...docRef.data(),
              })),
            )

            commit('UPDATE_TENANT_UI_LIBRARIES_LIST', tenantUILibraries)
            resolve(tenantUILibraries)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('tenant UI Libraries') }),
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches the active UI Library.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {Object} obj.dispatch
     *
     * @returns {Promise} - Returns error if the document doesn't exist.
     */
    fetchActiveUILibrary({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const q = query(uiLibraries, where('active', '==', true))

        getDocs(q)
          .then(async docSnapshot => {
            const [docRef] = docSnapshot.docs

            if (docRef === undefined) {
              resolve(null)

              return
            }

            // Fetch related resources
            const activeUILibraryFiles = await dispatch('fetchCurrentFiles', docRef?.id)
            const activeUILibraryExternalResources = await dispatch('fetchCurrentExternalResources', docRef?.id)

            const activeUILibrary = {
              data: docRef?.data(),
              files: activeUILibraryFiles,
              externalResources: activeUILibraryExternalResources,
            }

            commit('SET_ACTIVE_UI_LIBRARY', activeUILibrary)

            resolve(activeUILibrary)
          })
          .catch(error => {
            reject(error)

            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Active UI Library') }),
            })
          })
      })
    },

    /**
     * Fetches a UI Library by the given ID.
     *
     * @param {Object} obj
     * @param {string} id The document ID
     *
     * @returns {Promise}
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const docRef = doc(uiLibraries, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists() || docSnapshot.get('deletedAt') !== null) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the UI Library'),
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches the current UI Library by the given ID.
     *
     * @param {Object} obj
     * @param {Object} obj.dispatch
     * @param {Object} obj.commit
     * @param {string} id - The current UI Library ID
     *
     * @returns {Promise}
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(async res => {
            // Fetch related resources
            const currentFiles = await dispatch('fetchCurrentFiles', id)
            const currentExternalResources = await dispatch('fetchCurrentExternalResources', id)

            commit('SET_CURRENT', res)
            commit('SET_CURRENT_FILES', currentFiles)
            commit('SET_CURRENT_EXTERNAL_RESOURCES', currentExternalResources)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Fetches the files of the current UI Library by the given ID.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {string} id - The current UI Library ID
     *
     * @returns {Promise}
     */
    fetchCurrentFiles({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const filesCollection = collection(uiLibraries, id, 'files')

        const q = constructQuery(filesCollection, 'name', {
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(docSnapshot => {
            const files = docSnapshot.docs
              .map(docRef => ({
                id: docRef.id,
                ...docRef.data(),
              }))

            commit('SET_CURRENT_FILES', files)
            resolve(files)
          })
          .catch(error => {
            reject(error)

            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('UI Library\'s files') }),
            })
          })
      })
    },

    /**
     * Fetches the thumbnails of a UI Library by the given ID and target collection and bucket.
     *
     * @param {Object} obj
     * @param {Object} payload.id - The UI Library ID
     * @param {Object} payload.target - The target collection and bucket
     *
     * @returns {Promise}
     */
    fetchUILibraryThumbnails({ }, { id, target = 'specific' }) {
      return new Promise((resolve, reject) => {
        // Get the tenant-specific and shared UI Libraries collection
        const { uiLibraries, sharedUILibraries } = tenantCtx()

        // Get the tenant-specific and client themes root buckets
        const { uiLibrariesStorage, sharedUILibrariesStorage } = tenantCtx()

        const collections = {
          specific: uiLibraries,
          shared: sharedUILibraries,
        }

        const buckets = {
          specific: uiLibrariesStorage,
          shared: sharedUILibrariesStorage,
        }

        const filesCollection = collection(collections[target], id, 'files')

        const q = constructQuery(filesCollection, 'name', {
          where: [
            where('deletedAt', '==', null),
            where('type', '==', 'html'),
          ],
        })

        getDocs(q)
          .then(docSnapshot => {
            const files = docSnapshot.docs
            const thumbnails = []
            const devices = ['mobile', 'tablet', 'desktop']

            files.forEach(async file => {
              const thumbnailUrls = {}
              const fileData = file.data()

              devices.forEach(async device => {
                const fileThumbnailRef = storageRef(
                  buckets[target],
                  `${id}/${fileData.path}/images/${fileData.name}.${fileData.type}.${device}.png`,
                )

                thumbnailUrls[device] = await getDownloadURL(fileThumbnailRef)
              })

              thumbnails.push(thumbnailUrls)
            })

            resolve(thumbnails)
          })
          .catch(error => {
            console.error(error)

            reject(error)
          })
      })
    },

    /**
     * Fetches the thumbnails of a UI Component by the given UI Library ID and target bucket.
     *
     * @param {Object} obj
     * @param {Object} payload.uiLibraryId - The UI Library ID
     * @param {Object} payload.uiComponent - The UI Component
     * @param {Object} payload.target - The target bucket
     *
     * @returns {Promise}
     */
    fetchUIComponentThumbnails({ }, { uiLibraryId, uiComponent, target = 'specific' }) {
      return new Promise((resolve) => {
        const { uiLibrariesStorage, sharedUILibrariesStorage } = tenantCtx()

        const buckets = {
          specific: uiLibrariesStorage,
          shared: sharedUILibrariesStorage,
        }

        const thumbnailUrls = {}
        const devices = ['mobile', 'tablet', 'desktop']

        devices.forEach(async device => {
          const fileThumbnailRef = storageRef(
            buckets[target],
            `${uiLibraryId}/${uiComponent.path}/images/${uiComponent.name}.${uiComponent.type}.${device}.png`,
          )

          thumbnailUrls[device] = await getDownloadURL(fileThumbnailRef)
        })

        resolve(thumbnailUrls)
      })
    },

    /**
     * Fetches the current UI Library's External Resources.
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {string} id - The current UI Library ID
     *
     * @returns {Promise}
     */
    fetchCurrentExternalResources({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const externalResourcesCollection = collection(uiLibraries, id, 'externalResources')

        const q = constructQuery(externalResourcesCollection, 'order', {
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(docSnapshot => {
            const externalResources = docSnapshot.docs
              .map(docRef => ({
                id: docRef.id,
                ...docRef.data(),
              }))

            commit('SET_CURRENT_EXTERNAL_RESOURCES', externalResources)
            resolve(externalResources)
          })
          .catch(error => {
            reject(error)

            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', {
                title: i18n.t('UI Library\'s External Resources'),
              }),
            })
          })
      })
    },

    /**
     * Fetches a file of the current UI Library.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} payload - The payload used to fetch the file
     *
     * @returns {Promise}
     */
    fetchCurrentUILibraryFile({ state }, payload) {
      return new Promise(async (resolve, reject) => {
        const { uiLibrariesStorage } = tenantCtx()
        const fileRef = storageRef(
          uiLibrariesStorage,
          `${state.current.data.id}/${payload.path}/${payload.name}.${payload.type}`,
        )

        getBlob(fileRef)
          .then(fileBlob => {
            resolve(fileBlob)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', {
                title: i18n.t(`the content of ${payload.name}.${payload.type}`),
              }),
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a file of the active UI Library.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} payload - The payload used to fetch the file
     *
     * @returns {Promise}
     */
    fetchActiveUILibraryFile({ state }, payload) {
      return new Promise(async (resolve, reject) => {
        const { uiLibrariesStorage } = tenantCtx()
        const fileRef = storageRef(
          uiLibrariesStorage,
          `${state.activeUILibrary.data.id}/${payload.path}/${payload.name}.${payload.type}`,
        )

        getBlob(fileRef)
          .then(fileBlob => {
            resolve(fileBlob)
          })
          .catch(error => {
            store.dispatch('notify', {
              variant: 'danger',
              body: i18n.t('Something went wrong retrieving the {title}', {
                title: i18n.t(`the content of ${payload.name}.${payload.type}`),
              }),
            })

            reject(error)
          })
      })
    },

    /**
     * Updates the current UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} obj.dispatch
     * @param {Object} payload - The payload used to update the UI Library
     *
     * @returns {Promise}
     */
    updateCurrent({ state, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const previousValue = state.current.data
          const { id } = state.current.snapshot

          const value = {
            ...previousValue,
            ...payload,
            updatedAt: getUnix(),
          }

          dispatch('update', {
            value,
            previousValue,
          })

          dispatch('fetchCurrent', value.id) // Update the current data as well

          resolve(id)
        } catch (error) {
          console.debug(error)

          reject(error)
        }
      })
    },

    /**
     * Updates a UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload.value - The new value
     * @param {Object} payload.previousValue - The previous value
     *
     * @returns {Promise}
     */
    update({}, { value, previousValue }) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const docRef = doc(uiLibraries, value.id)

        updateDoc(docRef, value)
          .then(() => {
            store.dispatch('revisions/create', {
              id: value.id,
              previousValue,
              newValue: { ...value },
              event: RevisionEventType.UPDATE_UI_LIBRARIES,
            })

            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('UI Library') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Updates the current UI Library file by the given payload.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} obj.getters
     * @param {Object} payload - The payload used to update the file
     *
     * @returns {Promise}
     */
    updateCurrentUILibraryFile({ state, getters }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { uiLibraries, uiLibrariesStorage } = tenantCtx()
          const docRef = doc(uiLibraries, `${state.current.data.id}/files/${payload.id}`)

          // Let us see if we have to upload something
          if (payload.newValue !== payload.oldValue) {
            const file = `${state.current.data.id}/${payload.path}/${payload.name}.${payload.type}`
            const fileRef = storageRef(
              uiLibrariesStorage,
              file,
            )

            await uploadString(fileRef, payload.newValue)

            // Since we are already on the client side, we can easily take screenshots
            // of UI component elements to update the thumbnails. Doing this server-side is hard and inefficient.
            if (payload.type === 'html') {
              const current = getters.getCurrent
              const currentStyles = getters.getExternalResources('css')
              const currentScripts = getters.getExternalResources('js')
              const externalResources = retrieveExternalResources(current, currentStyles, currentScripts)

              const iframeDoc = updateIframeElement(payload.newValue, state.thumbnailCanvasElement, externalResources)
              const iframeParentElement = document.getElementById('thumbnailCanvasContainer')
              const deviceWidths = {
                mobile: 'w-50',
                tablet: 'w-75',
                desktop: 'w-100',
              }

              // Generates a canvas for each device by dynamically mimicking the device width
              const processDevice = async (device, width) => {
                iframeParentElement.classList.add(width) // Set the width class dynamically

                const thumbnailStoragePath = `${state.current.data.id}/${payload.path}/images/${payload.name}.${payload.type}.${device}.png`
                const canvas = await html2canvas(iframeDoc.body)
                const fileThumbnailRef = storageRef(uiLibrariesStorage, thumbnailStoragePath)
                const thumbnailDataUrl = canvas.toDataURL('image/png', 1)

                iframeParentElement.classList.remove(width)

                await uploadString(fileThumbnailRef, thumbnailDataUrl, 'data_url')
              }

              // Iterate over the deviceWidths and process each device
              const processPromises = Object.entries(deviceWidths).map(([device, width]) => processDevice(device, width))

              await Promise.all(processPromises)
            }

            // Sync the values for the participating units
            // and prevent unnecessary Storage updates
            payload.oldValue = payload.newValue
          }

          // Prevent unnecessary Firestore updates
          delete payload.changed

          const { newValue, oldValue, ...fileData } = payload

          await updateDoc(docRef, {
            ...fileData,
            updatedAt: getUnix(),
          })

          resolve(true)
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong updating the {title}', {
              title: i18n.t(`file ${payload.name}.${payload.type}`),
            }),
          })

          reject(error)
        }
      })
    },

    /**
     * Updates or inserts an External Resource of the current UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} payload - The payload used to update the External Resource
     *
     * @returns {Promise}
     */
    upsertCurrentUILibraryExternalResource({ state }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { uiLibraries } = tenantCtx()
          const externalResources = collection(uiLibraries, state.current.data.id, 'externalResources')

          if (!payload.id) {
            const docRef = doc(externalResources)

            setDoc(docRef, { ...payload }, { merge: true })
              .then(() => resolve(docRef))
              .catch(error => reject(error))
          } else {
            const docRef = doc(externalResources, payload.id)

            await updateDoc(docRef, {
              ...payload,
              updatedAt: getUnix(),
            })
          }

          resolve(true)
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong updating the {title}', {
              title: i18n.t(`External Resource ${payload.name}`),
            }),
          })

          reject(error)
        }
      })
    },

    /**
     * Deletes a file of the current UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} payload - The payload used to delete the file
     *
     * @returns {Promise}
     */
    deleteCurrentUILibraryFile({ state }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { uiLibraries } = tenantCtx()
          const { uiLibrariesStorage } = tenantCtx()
          const docRef = doc(uiLibraries, `${state.current.data.id}/files/${payload.id}`)

          const fileRef = storageRef(
            uiLibrariesStorage,
            `${state.current.data.id}/${payload.path}/${payload.name}.${payload.type}`,
          )

          await deleteObject(fileRef)

          // Soft delete in the DB
          await updateDoc(docRef, {
            deletedAt: getUnix(),
          })

          resolve(true)
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong deleting the {title}', {
              title: i18n.t(`file ${payload.name}.${payload.type}`),
            }),
          })

          reject(error)
        }
      })
    },

    /**
     * Deletes an External Resources of the current UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} payload - The payload used to delete the External Resource
     *
     * @returns {Promise}
     */
    async deleteCurrentUILibraryExternalResource({ state }, payload) {
      return new Promise((resolve, reject) => {
        try {
          const { uiLibraries } = tenantCtx()
          const docRef = doc(uiLibraries, `${state.current.data.id}/externalResources/${payload.id}`)

          // Soft delete in the DB
          updateDoc(docRef, {
            deletedAt: getUnix(),
          })
            .then(() => {
              resolve(true)
            })
        } catch (error) {
          console.debug(error)

          store.dispatch('notify', {
            variant: 'danger',
            body: i18n.t('Something went wrong deleting the {title}', {
              title: i18n.t(`External Resource ${payload.name}`),
            }),
          })

          reject(error)
        }
      })
    },

    /**
     * Listens/subscribes to Firestore changes of the current UI Library.
     *
     * @param {Object} obj
     * @param {Object} obj.state
     * @param {Object} obj.commit
     *
     * @returns {Object}
     */
    async currentUILibraryListener({ state, commit }) {
      const { uiLibraries } = tenantCtx()
      const docRef = doc(uiLibraries, state.current.data.id)

      // Now listen to changes
      const unsubscribe = onSnapshot(docRef, (doc) => {
        commit('SET_CURRENT', doc)
      })

      return unsubscribe
    },

    /**
     * Soft deletes a UI Library by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload - The payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { uiLibraries } = tenantCtx()
        const docRef = doc(uiLibraries, payload.id)

        updateDoc(docRef, {
          deletedAt: getUnix(),
        })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_UI_LIBRARIES,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })

            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Sets the thumbnail canvas element.
     *
     * @param {Object} obj.commit
     * @param {Object} element - The thumbnail canvas element
     *
     * @returns {void}
     */
    setThumbnailCanvasElement({ commit }, element) {
      commit('SET_THUMBNAIL_CANVAS_ELEMENT', element)
    },
  },
}
