import {
  addDoc, deleteDoc, doc, getDoc, getDocs, updateDoc,
} from 'firebase/firestore'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import i18n from '@/libs/i18n'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    list: [],
    current: {},
    activeTab: parseInt(localStorage.getItem('novti-integrations-active-tab'), 10) || 0,
  },
  getters: {
    getAll: state => state.list,
    getCurrent: state => state.current,
    getActiveTab: state => state.activeTab,
    getNativeIntegrations: state => state.list.filter(el => el.type !== 'custom'),
    getCustomIntegrations: state => state.list.filter(el => el.type === 'custom'),
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      state.list = payload
    },
    SET_CURRENT(state, payload) {
      state.current = {
        snapshot: payload,
        data: { ...payload.data(), id: payload.id },
      }
    },
    UPDATE_ACTIVE_TAB(state, payload) {
      localStorage.setItem('novti-integrations-active-tab', payload)
      state.activeTab = payload
    },
  },
  actions: {
    /**
     * Fetches all.
     *
     * @param {Object} obj
     * @param {Object} obj.commit Commit event
     *
     * @returns {Promise}
     */
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()

        getDocs(integrations)
          .then(querySnapshot => {
            const data = querySnapshot.docs
              .map(docRef => ({
                id: docRef.id,
                ...docRef.data(),
              }))

            commit('UPDATE_LIST', data)
            resolve(data)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Integrations') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the given ID
     *
     * @param {string} id The document ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()
        const docRef = doc(integrations, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('Integration') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Updates a document by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload Given payload
     *
     * @returns {Promise<boolean|Error>}
     */
    update({}, payload) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()
        const docRef = doc(integrations, payload.id)

        getDoc(docRef).then(() => {
          updateDoc(docRef, payload)
            .then(() => {
              store.dispatch('notify', { body: i18n.t('{title} has been updated', { title: 'Integration' }) })
              resolve(true)
            })
            .catch(error => {
              store.dispatch('notify', {
                body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('Integration') }),
                variant: 'danger',
              })

              reject(error)
            })
        })
      })
    },

    /**
     * Creates a document by the given payload.
     *
     * @param {Object} obj
     * @param {Object} payload Given payload
     *
     * @returns {Promise<DocumentReference|Error>}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()

        addDoc(integrations, payload)
          .then(newDocRef => {
            store.dispatch('notify', {
              title: i18n.t('Great!'),
              body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('Integration') }),
            })

            resolve(newDocRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the {title}', { title: i18n.t('Integration') }),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Create or update the record depending if the document id
     * can be found within the given payload
     *
     * @param {Object} obj
     * @param {Object} obj.dispatch  Dispatch event
     * @param {Object} payload       Given payload
     */
    upsert({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const action = payload?.id !== undefined ? 'update' : 'create'

        dispatch(action, payload).then(ref => {
          resolve(ref)
        }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    delete({}, payload) {
      return new Promise((resolve, reject) => {
        const { integrations } = tenantCtx()
        const docRef = doc(integrations, payload.id)

        deleteDoc(docRef)
          .then(() => resolve(true))
          .catch(err => reject(err))
      })
    },
  },
}
