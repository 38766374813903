/* eslint-disable no-restricted-syntax */
import store from '@/store'

export default function canvas() {
  const scripts = []
  const styles = [
    'https://cdn.form.io/formiojs/formio.full.min.css',
  ]

  // Get the active UI Library
  const activeUILibrary = store.getters['uiLibraries/getActiveUILibrary']
  const activeUILibraryStyles = store.getters['uiLibraries/getActiveExternalResources']('css')
  const activeUILibraryScripts = store.getters['uiLibraries/getActiveExternalResources']('js')

  if (activeUILibrary) {
    for (const script of activeUILibraryScripts) {
      scripts.push(script.src)
    }

    for (const style of activeUILibraryStyles) {
      styles.push(style.src)
    }

    styles.push(`${process.env.VUE_APP_CDN_URL}/${activeUILibrary.data.storage}/public/styles.bundle.css`)
    scripts.push(`${process.env.VUE_APP_CDN_URL}/${activeUILibrary.data.storage}/public/app.bundle.js`)
  }

  return {
    canvas: {
      styles,
      scripts,
    },
  }
}
