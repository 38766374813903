import {
  ref,
  uploadBytes,
} from 'firebase/storage'
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  query,
  where,
  orderBy,
  writeBatch,
  collection,
} from 'firebase/firestore'
import { getUnix } from '@/libs/date-format'
import { uniqBy, isEmpty } from 'lodash'
import { db } from '@/libs/firebase'
import { RevisionEventType } from '@core/revisions/revisions'
import { constructQuery } from '@core/utils/utils'
import { allowed, FEATURES } from '@/auth'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import router from '@/router'
import { transformForStorage } from '@/libs/content-translator/mutator'

/**
 * Removes form from dependent pages by a given form payload
 *
 * @param {Object} payload
 *
 * @returns {Promise}
 */
const handleFormDeletionDependencies = payload => new Promise((resolve, reject) => {
  const { pages } = tenantCtx()
  const q = query(pages, where('formId', '==', payload.id))

  getDocs(q)
    .then(querySnapshot => {
      querySnapshot.forEach(document => {
        updateDoc(document.ref, {
          formId: null,
        })
      })
      resolve(querySnapshot)
    })
    .catch(error => reject(error))
})

export default {
  namespaced: true,
  state: {
    list: [],
    listRelated: [],
    current: {
      snapshot: {},
      data: {
        schema: '{}',
      },
    },
    translations: [],
  },
  getters: {
    getErrors: state => state.errors,
    getCurrent: state => state.current,
    getById: state => id => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })).filter(el => !el.deletedAt).find(item => item.id === id),
    getAll: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })).map(({ schema, ...item }) => item).filter(el => !el.deletedAt),
    getFilteredList: state => state.list.map(docRef => ({ ...docRef.data(), id: docRef.id })).map(({ schema, ...item }) => item).filter(el => !el.deletedAt),
    getRelated: state => state.listRelated.map(docRef => ({ ...docRef.data(), id: docRef.id })).map(({ schema, ...item }) => item).filter(el => !el.deletedAt),
    getTranslations: state => state.translations,
  },
  mutations: {
    UPDATE_LIST(state, payload) {
      const { docs, pagination } = payload

      state.list = (!pagination) ? docs : uniqBy([...state.list, ...docs], 'id')
    },
    UPDATE_LIST_RELATED(state, payload) {
      state.listRelated = payload
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.list = payload
    },
    UPDATE_TRANSLATIONS(state, payload) {
      state.translations = payload
    },
    SET_CURRENT(state, payload) {
      state.current.snapshot = payload
      state.current.data = ({ ...payload.data(), id: payload.id })
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()

        const { queryOptions, pagination = false } = payload

        const q = constructQuery(forms, 'description', {
          ...queryOptions,
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST', { docs: querySnapshot.docs, pagination })
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the Forms'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()

        const q = constructQuery(forms, 'createdAt', {
          ...queryOptions,
          where: [...queryOptions.where, where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the forms'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the given ID
     *
     * @param {Object} id The page ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()
        const docRef = doc(forms, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            resolve(docSnapshot)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the Form'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id The current page ID
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Creates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.FORMS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your forms limit'),
            variant: 'danger',
          })

          return
        }

        const { forms } = tenantCtx()

        // This is required if we want to filter on `deletedAt == null`, as per Firestore query limitations
        // eslint-disable-next-line no-param-reassign
        payload.deletedAt = null

        const docRef = doc(forms)

        setDoc(docRef, payload)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_FORMS,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })
            store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('form') }) })

            resolve(docRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the Form'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} obj
     * @param {Object} obj.dispatch
     * @param {Object} obj.state
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    updateCurrent({ dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        const { formsStorage } = tenantCtx()

        const { previousValue, translations, data } = payload

        updateDoc(state.current.snapshot.ref, data)
          .then(async () => {
            try {
              const {
                snapshot: { id },
                data: { schema },
                data: currentData,
              } = state.current

              if (schema) {
                const jsonRef = ref(formsStorage, `${id}.json`)
                const jsonBlob = new Blob([schema], { type: 'application/json' })

                uploadBytes(jsonRef, jsonBlob)
              }

              if (!isEmpty(translations)) {
                dispatch('updateTranslations', {
                  id,
                  translations,
                })
              }

              store.dispatch('revisions/create', {
                event: RevisionEventType.UPDATE_FORMS,
                id,
                newValue: { ...currentData },
                previousValue,
              })

              store.dispatch('notify', { body: i18n.t('{title} has been updated', { title: i18n.t('Form') }) })
              resolve(true)
            } catch (error) {
              store.dispatch('notify', {
                body: i18n.t('Something went wrong storing the form!'),
                variant: 'danger',
              })

              reject(error)
            }
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the form!'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()
        const docRef = doc(forms, payload.id)

        handleFormDeletionDependencies(payload)

        updateDoc(docRef, {
          deletedAt: payload.deletedAt,
        })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_FORMS,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Duplicates a document
     *
     * @param {Object}
     * @param {string} id The id of the document to duplicate
     *
     * @returns {Promise}
     */
    duplicate({}, id) {
      return new Promise(async (resolve, reject) => {
        try {
          const timestamp = getUnix()
          const { forms } = tenantCtx()
          const docRef = doc(forms, id)
          const docSnapshot = await getDoc(docRef)
          const docSnapshotData = docSnapshot.data()
          const newDocRef = doc(forms)

          const data = {
            ...docSnapshotData,
            id: newDocRef.id,
            description: `${docSnapshotData.description} ${timestamp} - ${i18n.t('COPY')}`,
            createdAt: timestamp,
            deletedAt: null,
            updatedAt: null,
          }

          await setDoc(newDocRef, data)

          await store.dispatch('revisions/create', {
            event: RevisionEventType.DUPLICATE_FORMS,
            id: newDocRef.id,
            newValue: data,
            previousValue: {},
          })

          await router.push({
            name: 'forms-edit',
            params: { id: newDocRef.id },
          })

          await store.dispatch('notify', {
            title: i18n.t('Great!'),
            body: i18n.t('A new {title} has been duplicated 🚀', { title: i18n.t('form') }),
          })

          resolve(newDocRef)
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong duplicating the {title}', { title: i18n.t('form') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },

    /**
     * Fetch all forms which correspond to having a specific Form Configuration Id
     *
     * @param {Object}
     * @param {string} id Id of the form configuration
     *
     * @returns {Promise}
     */
    fetchAllByConfigurationId({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()

        const q = query(forms, where('formConfigurationId', '==', id), orderBy('createdAt', 'desc'))

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_LIST_RELATED', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetch all the translations by the given id
     *
     * @param {Object} obj
     * @param {Object} obj.commit
     * @param {string} id
     *
     * @returns {Promise<{[language: string]: { [key: string]: string }}>}
     */
    fetchAllTranslations({ commit }, id) {
      return new Promise((resolve, reject) => {
        const { forms } = tenantCtx()

        getDocs(collection(forms, id, 'translations')).then(querySnapshot => {
          const textContentsPerLanguage = querySnapshot.docs.reduce((acc, curr) => {
            acc[curr.id] = curr.data()

            return acc
          }, {})

          commit('UPDATE_TRANSLATIONS', textContentsPerLanguage)
          resolve(textContentsPerLanguage)
        }).catch(error => {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong retrieving the {title}', { title: i18n.t('translations') }),
            variant: 'danger',
          })

          reject(error)
        })
      })
    },

    /**
     * Update the translations
     *
     * @param {Object} payload
     *
     * @returns {Promise<Boolean>}
     */
    updateTranslations({ state }, payload) {
      return new Promise((resolve, reject) => {
        const { forms, formsStorage } = tenantCtx()

        try {
          const { id } = payload
          let { translations = [] } = payload

          const deletedLanguages = Object.keys(state.translations).filter(language => !translations[language]) ?? []

          if (deletedLanguages.length === 0 && translations.length === 0) {
            resolve(true)

            return
          }

          translations = transformForStorage(translations)

          const batch = writeBatch(db)
          const translationsCollection = collection(forms, id, 'translations')

          // Upsert translations
          Object.keys(translations).forEach(language => {
            const docRef = doc(translationsCollection, language)

            batch.set(docRef, translations[language])
          })

          // Delete translations
          Object.values(deletedLanguages).forEach(language => {
            const docRef = doc(translationsCollection, language)

            batch.delete(docRef)
          })

          batch.commit().then(() => resolve(true))

          const translationsRef = ref(formsStorage, `/translations/${id}.json`)
          const translationBlob = new Blob([JSON.stringify({ ...translations })], { type: 'application/json' })

          uploadBytes(translationsRef, translationBlob)
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong updating the {title}', { title: i18n.t('translations') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },
  },
}
