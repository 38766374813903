import {
  getDocs,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  where,
} from 'firebase/firestore'
import { uniqBy, isEmpty, sortBy } from 'lodash'
import { getUnix } from '@/libs/date-format'
import { constructQuery } from '@core/utils/utils'
import { RevisionEventType } from '@core/revisions/revisions'
import store from '@/store'
import i18n from '@/libs/i18n'
import { getTenantContextInstance as tenantCtx } from '@/plugins/tenant'
import router from '@/router'
import { allowed, FEATURES } from '@/auth'

export default {
  namespaced: true,
  state: {
    list: [],
    templates: [],
    params: {},
    current: {
      connectorsPermissions: {},
      connectorsTriggerConditions: {},
    },
    filteredList: [],
    removeModal: {
      show: false,
      data: {},
    },
    node: {
      selected: null,
      created: null,
      edited: null,
      remove: null,
    },
    selected_connector: null,
    new_created_connector: false,
    mapping_connectors_fields: [],
  },
  getters: {
    params: state => state.params,
    getCurrent: state => state.current,
    getRemoveModal: state => state.removeModal,
    getAll: state => state.list,
    getById: state => id => state.list.filter(el => !el.deletedAt).find(item => item.id === id),
    getFilteredList: state => state.filteredList.map(docRef => ({ ...docRef.data(), id: docRef.id })),
    getCurrentConnectorsMappings: state => JSON.parse(state.current.connectorsMappings ?? '{}'),
    getCurrentConnectorsInstructions: state => JSON.parse(state.current.connectorsInstructions ?? '{}'),
    getCurrentConnectorsPermissions: state => connector => (Object.prototype.hasOwnProperty.call(state.current?.connectorsPermissions ?? {}, connector) ? state.current?.connectorsPermissions[connector] ?? {} : {}),
    getCurrentConnectorsTriggerConditions: state => connector => (Object.prototype.hasOwnProperty.call(state.current.connectorsTriggerConditions ?? {}, connector) ? state.current.connectorsTriggerConditions[connector] ?? {} : {}),
    getCurrentConnectorsPrefillsInstructions: state => JSON.parse(state.current.connectorsPrefillsInstructions ?? '{}'),
    getMappingConnectorsFields: state => state.mapping_connectors_fields,
    getTemplates: state => sortBy(state.templates.map(docRef => ({ ...docRef.data(), id: docRef.id })), 'category'),
    // Define the getters for the `TreeNode`
    getSelectedConnector: state => state.selected_connector,
  },
  mutations: {
    FILTER_PARAMS(state, payload) {
      state.params = payload
    },
    UPDATE_LIST(state, payload) {
      const { docs, pagination } = payload

      state.list = (!pagination) ? docs : uniqBy([...state.list, ...docs], 'id')
    },
    UPDATE_TEMPLATES(state, payload) {
      state.templates = payload
    },
    UPDATE_FILTERED_LIST(state, payload) {
      state.filteredList = payload
    },
    SET_CURRENT(state, payload) {
      state.current = payload
    },
    STASH_CONNECTORS_MAPPINGS(state, payload) {
      // On empty null and make sure it is always JSON
      state.current.connectorsMappings = isEmpty(payload) ? null : JSON.stringify(payload)
    },
    STASH_CONNECTORS_INSTRUCTIONS(state, payload) {
      // On empty null and make sure it is always JSON
      state.current.connectorsInstructions = isEmpty(payload) ? null : JSON.stringify(payload)
    },
    STASH_CONNECTORS_PERMISSIONS(state, payload) {
      state.current.connectorsPermissions = { ...state.current.connectorsPermissions, ...payload }
    },
    STASH_CONNECTORS_TRIGGER_CONDITIONS(state, payload) {
      state.current.connectorsTriggerConditions = { ...state.current.connectorsTriggerConditions, ...payload }
    },
    STASH_CONNECTORS_PREFILLS_INSTRUCTIONS(state, payload) {
      // On empty null and make sure it is always JSON
      state.current.connectorsPrefillsInstructions = isEmpty(payload) ? null : JSON.stringify(payload)
    },
    STASH_MAPPING_CONNECTORS_FIELDS(state, payload) {
      state.mapping_connectors_fields = payload
    },
    // Define the mutations for the `TreeNode` changes
    SET_NODE_SELECTED(state, payload) {
      state.node.selected = payload
    },
    SET_NODE_CREATED(state, payload) {
      state.node.created = payload
    },
    SET_NODE_EDITED(state, payload) {
      state.node.edited = payload
    },
    NODE_REMOVE(state, payload) {
      state.node.remove = payload
    },
    SET_SELECTED_CONNECTOR(state, payload) {
      state.selected_connector = payload
    },
    NEW_CREATED_CONNECTOR(state, payload) {
      state.new_created_connector = payload
    },
  },
  actions: {
    /**
     * Fetches all
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchAll({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        const { formConfigurations } = tenantCtx()

        const { queryOptions = {}, pagination = false } = payload

        const q = constructQuery(formConfigurations, 'updatedAt', {
          ...queryOptions,
          where: [where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(async querySnapshot => {
            const revisionsPromises = []
            const docs = []

            // eslint-disable-next-line no-restricted-syntax
            for (const document of querySnapshot.docs) {
              revisionsPromises.push(
                store.dispatch('revisions/fetchById', document.id).then(revision => {
                  docs.push({
                    ...document.data(),
                    updatedBy: revision.user,
                    id: document.id,
                  })
                }),
              )
            }

            await Promise.all(revisionsPromises)

            commit('UPDATE_LIST', { docs, pagination })
            resolve(docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the Form Configurations'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },
    /**
     * Fetches the predefined shared templates/formConfigurations from the _shared tenant context.
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchTemplates({ commit }) {
      return new Promise((resolve, reject) => {
        const { sharedFormConfigurations } = tenantCtx()

        const q = constructQuery(sharedFormConfigurations, 'updatedAt')

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_TEMPLATES', querySnapshot.docs)

            resolve(querySnapshot.docs)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /**
     * Fetches a document by the given ID
     *
     * @param {Object} id The page ID
     *
     * @returns {Promise} Retrieves all fields in the document as an Object. Returns undefined if the document doesn't exist.
     */
    fetchById({}, id) {
      return new Promise((resolve, reject) => {
        const { formConfigurations } = tenantCtx()
        const docRef = doc(formConfigurations, id)

        getDoc(docRef)
          .then(docSnapshot => {
            if (!docSnapshot.exists()) {
              reject(new Error('404'))
            }

            resolve(docSnapshot.data())
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the Form Configuration'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Fetches a document by the current item ID
     *
     * @param {string} id The current page ID
     */
    fetchCurrent({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        dispatch('fetchById', id)
          .then(res => {
            commit('SET_CURRENT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Fetches on search query
     *
     * @param {Object}
     * @param {Object} queryOptions
     *
     * @returns {Promise}
     */
    fetchFiltered({ commit }, queryOptions) {
      return new Promise((resolve, reject) => {
        const { formConfigurations } = tenantCtx()

        const q = constructQuery(formConfigurations, 'updatedAt', {
          ...queryOptions,
          where: [...queryOptions.where, where('deletedAt', '==', null)],
        })

        getDocs(q)
          .then(querySnapshot => {
            commit('UPDATE_FILTERED_LIST', querySnapshot.docs)
            resolve(querySnapshot.docs)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong retrieving the form configurations'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * resets the filter list
     *
     * @param {Object}
     *
     * @returns {Promise}
     */
    resetFiltered({ commit }) {
      return new Promise(resolve => {
        commit('UPDATE_FILTERED_LIST', [])
        resolve()
      })
    },

    /**
     * Creates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    create({}, payload) {
      return new Promise((resolve, reject) => {
        if (!allowed(FEATURES.FORM_CONFIGURATIONS_QUOTA)) {
          reject()

          store.dispatch('notify', {
            body: i18n.t('You have reached your form configurations limit'),
            variant: 'danger',
          })

          return
        }

        const { formConfigurations } = tenantCtx()
        const docRef = doc(formConfigurations)

        setDoc(docRef, { ...payload, id: docRef.id, deletedAt: null })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.CREATE_FORM_CONFIGURATIONS,
              id: docRef.id,
              newValue: payload,
              previousValue: {},
            })
            store.dispatch('notify', { title: i18n.t('Great!'), body: i18n.t('A new {title} has been created 🚀', { title: i18n.t('form configuration') }) })
            resolve(docRef)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong creating the Form Configuration'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Updates a document by the given payload
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    update({ dispatch }, { value, previousValue }) {
      return new Promise((resolve, reject) => {
        const { formConfigurations } = tenantCtx()
        const docRef = doc(formConfigurations, value.id)

        updateDoc(docRef, value)
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.UPDATE_FORM_CONFIGURATIONS,
              id: value.id,
              newValue: { ...value },
              previousValue,
            })
            store.dispatch('notify', { body: i18n.t('Form Configuration has been updated') })
            dispatch('fetchCurrent', value.id) // Update the current data as well
            resolve(true)
          })
          .catch(error => {
            store.dispatch('notify', {
              body: i18n.t('Something went wrong updating the Form Configuration'),
              variant: 'danger',
            })

            reject(error)
          })
      })
    },

    /**
     * Soft deletes a document
     *
     * @param {Object} payload
     *
     * @returns {Promise}
     */
    softDelete({}, payload) {
      return new Promise((resolve, reject) => {
        const { formConfigurations } = tenantCtx()
        const docRef = doc(formConfigurations, payload.id)

        updateDoc(docRef, {
          deletedAt: getUnix(),
        })
          .then(() => {
            store.dispatch('revisions/create', {
              event: RevisionEventType.DELETE_FORM_CONFIGURATIONS,
              id: payload.id,
              newValue: {},
              previousValue: payload,
            })
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     * Duplicates a document
     *
     * @param {Object}
     * @param {string} id The id of the document to duplicate
     *
     * @returns {Promise}
     */
    duplicate({}, id) {
      return new Promise(async (resolve, reject) => {
        try {
          const timestamp = getUnix()
          const { formConfigurations } = tenantCtx()

          const docRef = doc(formConfigurations, id)
          const docSnapshot = await getDoc(docRef)
          const docSnapshotData = docSnapshot.data()

          const newDocRef = doc(formConfigurations)

          const data = {
            ...docSnapshotData,
            id: newDocRef.id,
            description: `${docSnapshotData.description} ${timestamp} - ${i18n.t('COPY')}`,
            // Set all timestamp properties.
            createdAt: timestamp,
            deletedAt: null,
            updatedAt: null,
          }

          await setDoc(newDocRef, data)

          await store.dispatch('revisions/create', {
            event: RevisionEventType.DUPLICATE_FORM_CONFIGURATIONS,
            id: newDocRef.id,
            newValue: data,
            previousValue: {},
          })

          await router.push({
            name: 'form-configurations-configurations-edit',
            params: { id: newDocRef.id },
          })
          await store.dispatch('notify', {
            title: i18n.t('Great!'),
            body: i18n.t('A new {title} has been duplicated 🚀', { title: i18n.t('form configuration') }),
          })
          resolve(newDocRef)
        } catch (error) {
          store.dispatch('notify', {
            body: i18n.t('Something went wrong duplicating the {title}', { title: i18n.t('form configuration') }),
            variant: 'danger',
          })

          reject(error)
        }
      })
    },
  },
}
